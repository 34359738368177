import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"


export const query = graphql`
  query Legal($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      ...Yoast
      id
      title
      modified: modified(formatString: "Do MMMM YYYY")
      content
    }
  }
`;


const Heading = styled.h1`

  line-height:1.2;
  font-weight: 600;
  margin-bottom:.75rem;
  font-size: 42px;
  @media screen and (min-width:768px) {
    font-size: 54px;
  }
`

const IndexPage = ({data: {page}}) => (

  <Layout>
    <SEO yoast={page.yoast_meta} />

    <section className="bg-tertiary md:py-24 sm:py-20 py-10">
      <div className="o-wrapper">
        <div className="max-w-3xl mx-auto">
          <Heading dangerouslySetInnerHTML={{__html:page.title}}/>
          <p className="mt-1">Last updated: {page.modified}</p>
        </div>
      </div>
    </section>

    <section className="bg-white md:py-24 sm:py-20 py-10">
      <div className="o-wrapper">
        <div className="max-w-3xl mx-auto">
          <div className="post-content" dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
